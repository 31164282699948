const StickyBar = () => {
    const menu = [
        { id: "#whatisfunsai", name: "Home" },
        { id: "#ecosystem", name: "ECOSYSTEM" },
        { id: "#compliance", name: "COMPLIANCE" },
        { id: "#aimanifesto", name: "AI MANIFESTO" },
        { id: "#universe", name: "PREMIUM PASS" },
        { id: "#faq", name: "FAQ" },
        { id: "#aboutus", name: "ABOUT US" },
    ];
    return (
        <div className="sticky bottom-5 z-50">
            <div className="flex justify-center items-center gap-x-1 md:gap-x-10 rounded-b-[100px] bg-[#c9b4de2c] backdrop-blur-sm w-fit mx-auto px-7 md:px-16 py-1 md:py-4 text-6-12 font-medium">
                <div className="flex items-center gap-x-3">
                    <img src="/assets/icon/funs.ai-icon.svg" alt="" className="rounded-full w-6 md:w-9" />
                    <div className="text-white">
                        <h4 className="font-medium text-xl">Funs.AI</h4>
                        <p className="text-[10px] -mt-[2px]">Powered by Knowpia ®</p>
                    </div>
                </div>
                {menu.map((item, i) => {
                    return (
                        <a
                            key={i}
                            href={item.id}
                            className=" text-white duration-150 hover:bg-[#EEEBF21A] rounded px-3 py-2"
                        >
                            {item.name}
                        </a>
                    );
                })}
                <a
                    href="/assets/pdf/KNOWPIA WHITE PAPER V 1.23.pdf"
                    className="uppercase text-white duration-150 hover:bg-[#EEEBF21A] rounded px-3 py-2"
                    target="_blank"
                >
                    White Paper V1.23
                </a>
            </div>
        </div>
    );
};

export default StickyBar;

// const StickyBar = () => {
//     const menu = [
//         { id: "#universe", name: "universe" },
//         { id: "#ourstory", name: "our story" },
//         { id: "#whatisfunsai", name: "what is funsai" },
//         { id: "#faq", name: "faq" },
//         { id: "#aboutus", name: "about us" },
//     ];
//     return (
//         <div className="sticky bottom-5 z-50">
//             <div className="flex justify-center items-center gap-x-1 md:gap-x-10 rounded-b-[100px] bg-[#c9b4de2c] backdrop-blur-sm w-fit mx-auto px-7 md:px-16 py-1 md:py-4 text-6-12 font-medium">
//                 <img src="/assets/icon/funs.ai-icon.svg" alt="" className="rounded-full w-6 md:w-9" />
//                 {menu.map((item, i) => {
//                     return (
//                         <a
//                             key={i}
//                             href={item.id}
//                             className="uppercase text-white duration-150 hover:bg-[#EEEBF21A] rounded px-3 py-2"
//                         >
//                             {item.name}
//                         </a>
//                     );
//                 })}
//                 <a
//                     href="/assets/pdf/KNOWPIA WHITE PAPER.pdf"
//                     className="uppercase text-white duration-150 hover:bg-[#EEEBF21A] rounded px-3 py-2"
//                     target="_blank"
//                 >
//                     White Paper
//                 </a>
//             </div>
//         </div>
//     );
// };

// export default StickyBar;
